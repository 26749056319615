import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll';

export default function SQAsection() {
  return (
    <section id="faq" className="section-padding">
        <div className="container">
            <div className="row">
                <div className="col-xs-12 text-center">
                    <h3 className="page-title">Q & A</h3>
                    <div className="space-60"></div>
                </div>
            </div>
            <div className="row ms-items">
                <div className="col-xs-12 col-md-6 ms-item">
                <AnimationOnScroll animateIn="animate__fadeInUp" delay={200} animateOnce={true} >
                    <div className="single-faq wow fadeInUp" data-wow-delay="0.2s">
                        <h5 className="title">What is Xscope Measuring?</h5>
                        <p>Xscope Measuring is a cloud-based web measurement platform designed to help contractors save
                            time, increase sales and grow their business.
                            Xscope Measuring provides contractors with the necessary tools to create their own satellite
                            survey reports on site.</p>
                    </div>
                </AnimationOnScroll>
                </div>
                <div className="col-xs-12 col-md-6 ms-item">
                    <AnimationOnScroll animateIn="animate__fadeInUp" delay={400} animateOnce={true}>
                    <div className="single-faq wow fadeInUp" data-wow-delay="0.4s">
                        <h5 className="title">What features are available in Xscope Measuring?</h5>
                        <p> Xscope Measuring has collaboration tools such as chat between team members.
                            Xscope Measuring innovates the workflow by enabling the option to take photos for each
                            project where each photo can be edited, tagged and shared by members of the same team. </p>
                    </div>
                    </AnimationOnScroll>
                </div>
                <div className="col-xs-12 col-md-6 ms-item">
                    <AnimationOnScroll animateIn="animate__fadeInUp" delay={600} animateOnce={true}>
                    <div className="single-faq wow fadeInUp" data-wow-delay="0.6s">
                        <h5 className="title">How does Xscope Measuring keep my information safe?</h5>
                        <p>Xscope Measuring keeps your information safe using high-end SSL encryption on Amazon servers,
                            we also use Amazon's S3 Cloud to store your photos online.
                            Members of your team will not be able to delete project information. </p>
                    </div>
                    </AnimationOnScroll>
                </div>
                <div className="col-xs-12 col-md-6 ms-item">
                    <AnimationOnScroll animateIn="animate__fadeInUp" delay={800} animateOnce={true}>
                    <div className="single-faq wow fadeInUp" data-wow-delay="0.8s">
                        <h5 className="title">How do you use Xscope Measuring?</h5>
                        <p>
                            Sign up, purchase a 1-month or 1-year subscription, Create a new Project, enter the address,
                            then create your own estimate using the Draw tools.
                        </p>
                    </div>
                    </AnimationOnScroll>
                </div>
                <div className="col-xs-12 col-md-6 ms-item">
                    <AnimationOnScroll animateIn="animate__fadeInUp" delay={1000} animateOnce={true}>
                    <div className="single-faq wow fadeInUp" data-wow-delay="1s">
                        <h5 className="title">Is there a limit to how many reports I can create?</h5>
                        <p>No, all subscriptions include unlimited number of reports. </p>
                    </div>
                    </AnimationOnScroll>
                </div>
                <div className="col-xs-12 col-md-6 ms-item">
                    <AnimationOnScroll animateIn="animate__fadeInUp" delay={1200} animateOnce={true}>
                    <div className="single-faq wow fadeInUp" data-wow-delay="1.2s">
                        <h5 className="title">Can I brand my own report?</h5>
                        <p>Yes, You can customize your report with your own logo.</p>
                    </div>
                    </AnimationOnScroll>
                </div>
            </div>
        </div>
    </section>
  )
}
