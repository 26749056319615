import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../auth/AuthContext";
import {PATHS} from '../const/routeNames';

const PublicRoute = ({ children }) => {

  const { user } = useContext(AuthContext);

  if (user.logged) {
    // user is authenticated
    return <Navigate to={PATHS.dashboard} />;
  }

  return children;
};

export default PublicRoute;
