import React from "react";
import { useState } from "react";
import EmailService from "../services/EmailService";
import { closeSwal, openNewLoading, Toast } from "../utils/utils";

const initialState = {
  company: "",
  email: "",
  message: "",
  name: "",
  subject: "",
};

const errorMessage = "There has been a problem. Please try again later.";
const successMessage = "The email was sent.";

export default function ContacFormSection() {
  const [form, setForm] = useState(initialState);

  const handleChange = (evt, key) =>
    setForm({ ...form, [key]: evt.target.value });

  const onSubmit = (evt) => {
    evt.preventDefault();

    openNewLoading("Sending Email...");

    EmailService.sendEmail(form)
      .then((resp) => {
        closeSwal();
        const { status } = resp;

        if (status !== "success")
          return Toast.fire({
            icon: "error",
            title: errorMessage,
          });

        Toast.fire({
          icon: "success",
          title: successMessage,
        });

        setForm(initialState);
      })
      .catch(() => {
        closeSwal();
        Toast.fire({
          icon: "error",
          title: errorMessage,
        });
      });
  };

  return (
    <section id="contact-page" className="section-padding">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 text-center">
            <h3 className="page-title">Contact us</h3>
            <div className="space-60"></div>
            <form action=""></form>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xs-12 col-md-10 col-md-offset-1">
            <form id="contact-form" onSubmit={(e) => onSubmit(e)}>
              <div className="form-double">
                <div className="form_group">
                  <input
                    name="form-name"
                    placeholder="Name"
                    className="form_control"
                    required="required"
                    value={form.name}
                    onChange={(e) => handleChange(e, "name")}
                  />
                </div>
                <div className="form_group last">
                  <input
                    type="email"
                    name="form-email"
                    placeholder="Email"
                    required="required"
                    className="form_control"
                    value={form.email}
                    onChange={(e) => handleChange(e, "email")}
                  />
                </div>
              </div>

              <input
                type="text"
                name="form-company"
                placeholder="Company Name"
                className="form_control"
                required="required"
                value={form.company}
                onChange={(e) => handleChange(e, "company")}
              />
              <input
                type="text"
                name="form-subject"
                placeholder="Subject"
                className="form_control"
                required="required"
                value={form.subject}
                onChange={(e) => handleChange(e, "subject")}
              />
              <textarea
                className="form_control"
                name="form-message"
                rows="5"
                placeholder="Message"
                required="required"
                value={form.message}
                onChange={(e) => handleChange(e, "message")}
              ></textarea>
              <div className="text-center">
                <button className="bttn-default bttn-lg" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
