import React from "react";
import Carousel from "react-bootstrap/Carousel";

export default function GallerySection() {
  return (
    <section className="gallery-area" id="gallery-page">
      <div>
        <div className="row mb-4">
          <div className="col-xs-12 text-center">
            <h3 className="page-title">Gallery</h3>
            <div className="space-30"></div>
          </div>
        </div>

        <Carousel
          className="container sdd"
          interval={null}
          prevIcon={<div className="carousel_control">{"‹"}</div>}
          nextIcon={<div className="carousel_control">{"›"}</div>}
          indicatorLabels={[1, 2, 3, 4, 5, 6]}
        >
          <Carousel.Item>
            <div className="item active">
              <div className="row">
                <div className="col-lg-6 col-xs-12">
                  <div className="thumbnail">
                    <img
                      src="images/Carrusel_img1.png"
                      alt="Carrusel 1"
                      className="imageCarrusel"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-xs-12">
                  <div className="thumbnail">
                    <img
                      src="images/Carrusel_img2.png"
                      alt="Carrusel 2"
                      className="imageCarrusel"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="item active">
              <div className="row">
                <div className="col-lg-6 col-xs-12">
                  <div className="thumbnail">
                    <img
                      src="images/Carrusel_img3.png"
                      alt="Carrusel 3"
                      className="imageCarrusel"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-xs-12 rounded-5">
                  <div className="thumbnail">
                    <img
                      src="images/pic2.png"
                      alt="Carrusel 4"
                      className="imageCarrusel"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="item active">
              <div className="row">
                <div className="col-lg-6 col-xs-12">
                  <div className="thumbnail">
                    <img
                      src="images/Carrusel_img5_rec.jpg"
                      alt="Carrusel 5"
                      className="imageCarrusel"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-xs-12">
                  <div className="thumbnail">
                    <img
                      src="images/pic3.png"
                      alt="Carrusel 6"
                      className="imageCarrusel"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="item active">
              <div className="row">
                <div className="col-lg-6 col-xs-12">
                  <div className="thumbnail">
                    <img
                      src="images/pic4.png"
                      alt="Carrusel 7"
                      className="imageCarrusel"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-xs-12">
                  <div className="thumbnail">
                    <img
                      src="images/pic5.png"
                      alt="Carrusel 8"
                      className="imageCarrusel"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="item active">
              <div className="row">
                <div className="col-lg-6 col-xs-12">
                  <div className="thumbnail">
                    <img
                      src="images/pic6.png"
                      alt="Carrusel 9"
                      className="imageCarrusel"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-xs-12">
                  <div className="thumbnail">
                    <img
                      src="images/pic7.png"
                      alt="Carrusel 10"
                      className="imageCarrusel"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="item active">
              <div className="row">
                <div className="col-lg-6 col-xs-12">
                  <div className="thumbnail">
                    <img
                      src="images/pic8.png"
                      alt="Carrusel 11"
                      className="imageCarrusel"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-xs-12">
                  <div className="thumbnail">
                    <img
                      src="images/pic9.png"
                      alt="Carrusel 4"
                      className="imageCarrusel"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </section>
  );

  
}
