import axios from "axios";
import { BASE_URL } from "../utils/constants";

class EmailService {
  async sendEmail(body) {
    var result = null;
    let url = `${BASE_URL}/api/landingPage/send-email`;

    await axios
      .post(url, body)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "error",
        };
      });

    return result;
  }
}

export default new EmailService();
