import { deleteDoc, doc } from 'firebase/firestore'
import { deleteObject, ref } from 'firebase/storage';
import React from 'react'
import { toast } from 'react-toastify';
import { db, storage } from '../firebaseConfig'

export default function DeleteArticle({id, imageUrl}) {
  
  function confirmDelete() {
    let text = "Are you sure you want to delete the post?";
    if (window.confirm(text) === true) {
      handleDelete();
    } 
  }

  const handleDelete = async ()=> {
    try {
        await deleteDoc(doc(db, "Articles", id));
        toast('Article deleted successfully', {type: 'success'});
        const storageRef = ref(storage, imageUrl);
        await deleteObject(storageRef);
    } catch (error) {
        toast('Error deleting article', {type: 'error'});
        console.log(error);
    }
  }
  
    return (
    <div>
        <button className='btn btn-danger' onClick={confirmDelete}>Delete</button>
    </div>
  )
}
