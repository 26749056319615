import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll';


export default function ServiceSection() {
  return (
    <section className="service-area section-padding-top" id="about-page">
        <div className="container">
            <div className="row">
                <div className="col-xs-12 text-center">
                    <h3 className="page-title">Why Choose Xscope Measuring</h3>
                    <div className="mt-5"></div>
                </div>
            </div>
            {/* cards */}
            <div className="row text-center">
                    <div className="col-xs-12 col-md-4 wow fadeInUp" data-wow-delay="0.3s">
                        <AnimationOnScroll animateIn="animate__fadeInUp" delay={300} animateOnce={true}>
                            <div className="service-box">
                                <div className="service-icon">
                                    <img src="images/xsc-icon-1.png" alt="service icon"/>
                                </div>
                                <h3 className="service-title">Estimate accurately </h3>
                                <p>Get a roof report or draw it yourself in minutes and send a professionally designed proposal
                                    to your client.</p>
                            </div>
                        </AnimationOnScroll>    
                    </div>

                <div className="col-xs-12 col-md-4 wow fadeInUp" data-wow-delay="0.5s">
                    <AnimationOnScroll animateIn="animate__fadeInUp" delay={500} animateOnce={true}>
                        <div className="service-box">
                            <div className="service-icon">
                                <img src="images/xsc-icon-2.png" alt="service icon"/>
                            </div>
                            <h3 className="service-title">Unlimited free reports</h3>
                            <p>Our platform is free to use for any contractor and you can measure from our satellite
                                imagery.</p>
                        </div>
                    </AnimationOnScroll>      
                </div>
                
                <div className="col-xs-12 col-md-4 wow fadeInUp" data-wow-delay="0.7s">
                    <AnimationOnScroll animateIn="animate__fadeInUp" delay={700} animateOnce={true}>
                        <div className="service-box">
                            <div className="service-icon">
                                <img src="images/xsc-icon-3.png" alt="service icon"/>
                            </div>
                            <h3 className="service-title">Dedicated support</h3>
                            <p>We have amazing agents to support and guide you on all the features of our platform.</p>
                        </div>
                    </AnimationOnScroll>    
                </div>
            </div>
        </div>
    </section>
  )
}
