import React from "react";
import "react-quill/dist/quill.snow.css";
import {useLocation} from 'react-router-dom';
import AddArticle from "../components/AddArticle";
import Navbar from "../components/Navbar";

export default function PostEditor() {

  const {state} = useLocation();
  // const { id, color } = state;

  return (
    <div>
      <Navbar />
      <div className="row justify-content-center pt-5">
        <div className="col-md-8">
          <AddArticle props={state? state: null}/>
        </div>
      </div>
    </div>
  );
}
