import React from 'react'
import Navbar from '../components/Navbar'

export default function MainTemplate({children}) {
  return (
    <>
       <Navbar/>
       <div className="mt-5 pt-2">
        {children}
       </div>
    </>
  )
}
