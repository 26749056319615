import React from "react";
import AboutSection from "../components/AboutSection";
import BlogSection from "../components/BlogSection";
import ContacFormSection from "../components/ContacFormSection";
import FeatureSection from "../components/FeatureSection";
import FooterSection from "../components/FooterSection";
import GallerySection from "../components/GallerySection";
import HeaderSection from "../components/HeaderSection";
import NavbarLanding from "../components/NavbarLanding";
import NetworkSection from "../components/NetworkSection";
import PriceSection from "../components/PriceSection";
import ServiceSection from "../components/ServiceSection";
import SQAsection from "../components/SQAsection";
import VideoSection from "../components/VideoSection";

export const Home = () => {
  return (
    <>
      <div>
        <NavbarLanding />
        <HeaderSection />
        <VideoSection />
        <ServiceSection />
        <AboutSection />
        <NetworkSection />
        <FeatureSection />
        <GallerySection />
        <PriceSection />
        <SQAsection />
        <BlogSection />
        <ContacFormSection />
        <FooterSection />
      </div>

    </>
  );
};
