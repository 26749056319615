import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebaseConfig";
import DeleteArticle from "./DeleteArticle";

export default function Articles() {
  const navigate = useNavigate();
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const articleRef = collection(db, "Articles");
    const q = query(articleRef, orderBy("createdAt", "desc"));
    onSnapshot(q, (snapshop) => {
      const articles = snapshop.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setArticles(articles);
      console.log(articles);
    });
  }, []);

  const editHandler = (article) => {
    navigate("/editor", { state: { article: article, edit: true } });
  };

  return (
    <div className="mb-5">
      {articles.length === 0 ? (
        <h3 className="mt-3 text-center">No articles found!</h3>
      ) : (
        articles.map((article) => (
          <div key={article.id} className="card mt-3 p-3 bg-light">
            <div className="row">
              <div className="d-flex col-3 align-items-center">
                <img
                  src={article.imageUrl}
                  alt="title"
                  style={{ height: 150, width: 150, objectFit: "cover" }}
                />
              </div>
              <div className="col-9">
                <h3 className="max-lines-2">{article.title}</h3>
                <p>{article.createdAt.toDate().toDateString()}</p>
                <div
                  dangerouslySetInnerHTML={{ __html: article.description }}
                  className="max-lines-3 mb-1"
                />

                <div className="row">
                  <div className="col-4 col-sm-2">
                    <button
                      className="btn btn-primary"
                      onClick={() => editHandler(article)}
                    >
                      Edit
                    </button>
                  </div>
                  <div className="col-3 col-sm-2">
                    <DeleteArticle
                      id={article.id}
                      imageUrl={article.imageUrl}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
}
