import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll';

export default function AboutSection() {
  return (
    <section className="social-area section-pd-top-2" id="home-page">
        <div className="container">
            <div className="row">
                <div className="col-sm-6 col-lg-6 hidden-small">
                    <img src="images/xsc-img-01.png" alt="roof"/>
                </div>

                <div className="col-xs-12 col-md-6">
                    <div className="social-logo wow fadeInUp">
                    <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
                        <img src="images/about-logo.png" alt="logo about"/>
                    </AnimationOnScroll>
                    </div>
                    <AnimationOnScroll animateIn="animate__fadeInUp" delay={500} animateOnce={true}>
                        <h3 className="page-title wow fadeInUp" data-wow-delay="0.5s">Simple and Accurate Reports you can trust
                        </h3>
                    </AnimationOnScroll>
                    <AnimationOnScroll animateIn="animate__fadeInUp" delay={700} animateOnce={true}>
                        <p className="wow fadeInUp" data-wow-delay="0.7s">The simplicity and accuracy of our platform allows you
                            to create professional roof measurements reports in hours. Roof reports will include all the
                            information you need to prepare estimates, including the total square footage of roofs and
                            lineal footage of walls, professional roof drawings while showing measured quantities. Get ready to work
                            efficiently!
                        </p>
                    </AnimationOnScroll>
                    <div className="space-30"></div>
                </div>
            </div>
        </div>
    </section>
  )
}
