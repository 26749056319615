import React, { useState } from "react";
import image from "../img/price-bg.png";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Nav } from "react-bootstrap";
import Switch from "react-switch";

export default function PriceSection() {

  const [yearly, setYearly] = useState(false);

  return (
    <section className="price-area section-pd-top-2" id="price-page">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 text-center">
            <h3 className="page-title">Pricing</h3>
            <div className="space-60"></div>
          </div>
        </div>
        <div className="switch_price_container">
          <h5>Monthly</h5>
          <Switch
            className=""
            onChange={() => setYearly(!yearly)}
            checked={yearly}
            uncheckedIcon={false}
            checkedIcon={false}
            offHandleColor="#ffffff"
            offColor="#ef884c"
            onHandleColor="#ef884c"
            onColor="#D9DCDB"
            handleDiameter={22}
            activeBoxShadow="0 0 2px 3px #3bf"
          />
          <h5>Yearly ( 10% OFF )</h5>
        </div>



        <div className="row">
          {/* Free User */}
          <div className="col-xs-12 col-md-3">
            <AnimationOnScroll
              animateIn="animate__fadeIn"
              delay={100}
              animateOnce={true}
            >
              <div
                className="price-box wow fadeInUp"
                style={{
                  backgroundImage: `url(${image})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPositionX: "right",
                }}
                data-wow-delay="0.3s"
              >
                <div className="price-icon">
                  <img width="60" src="images/xsc-icon-8.png" alt="icon" />
                </div>
                <h4 className="title">Free</h4>
                <strong>1 User</strong>
                <br />
                <br />
                <br />
                <br />
                <br />
                {/* <h3 className="price-rate">{yearly ? '$2473' : '$229'}</h3> */}
                {/* <p className="text_annually">{yearly ? 'per user / year' : 'per user / per month, billed annually ($2,748.00)'}</p> */}
                <a
                  href="https://xscope-measure.net/Signup"
                  className="bttn-default"
                >
                  {/* Free - 7 day trial */}
                  Get Started for Free
                </a>
                <hr />
                <ul>
                  <li className="text-start">Unlimited Projects</li>
                  <li className="text-start">Unlimited Satellite Measurements</li>
                  {/* <li className="text-start">Unlimited Estimates</li> */}
                  <li className="text-start">Limited Reports</li>
                  <li className="text-start">30 Photos Free</li>
                  <li className="text-start">Unlimited Videos</li>
                  <li className="text-start">Unlimited Chat</li>
                </ul>

              </div>
            </AnimationOnScroll>
          </div>


          {/* Single User */}
          <div className="col-xs-12 col-md-3">
            <AnimationOnScroll
              animateIn="animate__fadeIn"
              delay={300}
              animateOnce={true}
            >
              <div
                className="price-box wow fadeInUp"
                style={{
                  backgroundImage: `url(${image})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPositionX: "right",
                }}
                data-wow-delay="0.3s"
              >
                <div className="price-icon">
                  <img width="60" src="images/xsc-icon-8.png" alt="icon" />
                </div>
                <h4 className="title">Single user</h4>
                <strong>1 User</strong>
                <h3 className="price-rate">{yearly ? '$2473' : '$229'}</h3>
                <p className="text_annually">{yearly ? 'per user / year' : 'per user / per month, billed annually ($2,748.00)'}</p>
                <a
                  href="https://xscope-measure.net/Signup"
                  className="bttn-default"
                >
                  {/* Free - 7 day trial */}
                  Buy Single
                </a>
                <hr />
                <ul>
                  <li className="text-start">Unlimited Projects</li>
                  <li className="text-start">Unlimited Satellite Measurements</li>
                  {/* <li className="text-start">Unlimited Estimates</li> */}
                  <li className="text-start">Unlimited Reports</li>
                  <li className="text-start">Unlimited Photos</li>
                  <li className="text-start">Unlimited Videos</li>
                  <li className="text-start">Unlimited Chat</li>
                </ul>

              </div>
            </AnimationOnScroll>
          </div>

          {/* Business */}
          <div className="col-xs-12 col-md-3">
            <AnimationOnScroll
              animateIn="animate__fadeIn"
              delay={600}
              animateOnce={true}
            >
              <div
                className="price-box wow fadeInUp"
                style={{
                  backgroundImage: `url(${image})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPositionX: "right",
                }}
                data-wow-delay="0.6s"
              >
                <div className="price-icon">
                  <img width="60" src="images/xsc-icon-9.png" alt="icon" />
                </div>
                <h4 className="title">Business</h4>
                <strong>3 Users minimum</strong>
                <h3 className="price-rate">{yearly ? '$2149' : '$199'}</h3>
                <p className="text_annually">{yearly ? 'per user / year' :'per user / per month, billed annually ($2,388.00)'}</p>
                <a
                  href="https://xscope-measure.net/Signup"
                  className="bttn-default"
                >
                  Buy Business
                </a>
                <hr />
                <ul>
                  <li className="text-start">Unlimited Projects</li>
                  <li className="text-start">Unlimited Satellite Measurements</li>
                  {/* <li className="text-start">Unlimited Estimates</li> */}
                  <li className="text-start">Unlimited Reports</li>
                  <li className="text-start">Unlimited Photos</li>
                  <li className="text-start">Unlimited Videos</li>
                  <li className="text-start">Unlimited Chat</li>
                </ul>

              </div>
            </AnimationOnScroll>
          </div>

          {/* Enterprise */}
          <div className="col-xs-12 col-md-3">
            <AnimationOnScroll
              animateIn="animate__fadeIn"
              delay={900}
              animateOnce={true}
            >
              <div
                className="price-box wow fadeInUp"
                style={{
                  backgroundImage: `url(${image})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPositionX: "right",
                }}
                data-wow-delay="0.9s"
              >
                <div className="price-icon">
                  <img width="60" src="images/xsc-icon-10.png" alt="icon" />
                </div>
                <h4 className="title">Enterprise</h4>
                <strong>5 User minimun</strong>
                <h3 className="price-rate">{yearly ? '$1933' : '$179'}</h3>
                <p className="text_annually">{yearly ? 'per user / year': 'per user / per month, billed annually ($2,148.00)'}</p>
                <a
                  href="https://xscope-measure.net/Signup"
                  className="bttn-default"
                >
                  {/* Free - 7 day trial */}
                  Buy Enterprise
                </a>
                <hr className="hr_divider"/>
                <ul>
                  <li className="text-start">Unlimited Projects</li>
                  <li className="text-start">Unlimited Satellite Measurements</li>
                  {/* <li className="text-start">Unlimited Estimates</li> */}
                  <li className="text-start">Unlimited Reports</li>
                  <li className="text-start">Unlimited Photos</li>
                  <li className="text-start">Unlimited Videos</li>
                  <li className="text-start">Unlimited Chat</li>
                </ul>

              </div>
            </AnimationOnScroll>
          </div>
        </div>
      </div>



      <div className="price_contact_container">
        <img width="50" src="images/xsc-icon-10.png" alt="icon" />
        <h6 className="align-self-center m-0">
            Please contact us for customizable plans over 5 users
          </h6>
        <Nav.Link href="/#contact-page">
        <button className="bttn-default">Contact sales</button>
        </Nav.Link>
      </div>
    </section>
  );
}
