import React, { useEffect, useState } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';

export default function FeatureSection() {

  const [windowSize, setWindowSize] = useState(getWindowSize());

  function getWindowSize() {
    const {innerWidth:width, innerHeight: height} = window;
    return {width, height};
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  

  

  return (
    <section className="feature-area section-padding-top margin_button_section" id="feature-page">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12 text-center">
            <h3 className="page-title">Cool Features</h3>
            <div className="space-30"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 features">
          <OwlCarousel className='owl-theme' 
          loop margin={30}
          autoplay={true}
          smartSpeed={1000}
          items={
            windowSize.width>=1000? 4: 
            windowSize.width>600 && windowSize.width<1000? 2 : 1
            }>
            <div className="single-feature">
              <div className="feature-icon space">
                <img src="images/xsc-icon-6.png" alt="icon" />
              </div>
              <h4 className="title">Real Time Collaboration</h4>
            </div>
            <div className="single-feature">
              <div className="feature-icon space">
                <img src="images/xsc-icon-5.png" alt="icon" />
              </div>
              <h4 className="title">Cloud-Based</h4>
            </div>
            <div className="single-feature">
              <div className="feature-icon space">
                <img src="images/xsc-icon-4.png" alt="icon" />
              </div>
              <h4 className="title">Chat Team</h4>
            </div>
            <div className="single-feature">
              <div className="feature-icon space">
                <img src="images/xsc-icon-7.png" alt="icon" />
              </div>
              <h4 className="title">Dashboard</h4>
            </div>
            <div className="single-feature">
              <div className="feature-icon">
                <img src="images/feature-5.png" alt="icon" />
              </div>
              <h4 className="title">Contact Manager</h4>
            </div>
            <div className="single-feature">
              <div className="feature-icon">
                <img src="images/feature-6.png" alt="feature" />
              </div>
              <h4 className="title">Calendar</h4>
            </div>
            </OwlCarousel>  
          </div>
        </div>
      </div>
    </section>
  );
}
