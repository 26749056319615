import React from "react";
import { useNavigate } from "react-router-dom";
import { AnimationOnScroll } from "react-animation-on-scroll";
import {PATHS} from '../const/routeNames';

export default function MainCardPost({ article }) {

  const navigate = useNavigate();

  return (
    <div className="col-xs-12 col-md-4" role="button" onClick={()=>navigate(`${PATHS.blog}/${article.id}`)}>
      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
        <article
          className="blog-single wow fadeInUp main_car_blog"
          data-wow-delay="0.3s"
        >
          <div className="blog-photo">
            <img src={article.imageUrl} alt={article.title} />
          </div>
          <div className="blog-content">
            <h5 className="title">{article.title}</h5>
            <ul className="blog-meta">
              <li>
                <i className="zmdi zmdi-time"></i>{" "}
                {article.createdAt.toDate().toDateString()}
              </li>
            </ul>
          </div>
        </article>
      </AnimationOnScroll>
    </div>
  );
}
