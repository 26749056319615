import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyB8MLY1wI5WxTGiJBcPmo37i4CHYAkn8kw",
  authDomain: "xscopeblog.firebaseapp.com",
  projectId: "xscopeblog",
  storageBucket: "xscopeblog.appspot.com",
  messagingSenderId: "530399921779",
  appId: "1:530399921779:web:def592b17dde822dbe8c06",
  measurementId: "G-N7JTHFE59W",
};

const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
getAnalytics(app);
