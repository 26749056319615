
export const PATHS = {
     home: '/',
     blog: '/blog',
     blogDetail: '/blog/:postId',
     login: '/login',
     dashboard : '/dashboard',
     editor: '/editor',
} 


