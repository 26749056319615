import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "../pages/Login";
import PublicRoute from "../routers/publicRoutes";
import PrivateRoute from "../routers/privteRoutes";
import MainTemplate from "../templates/MainTemplate";
import { Dashboard } from "../pages/Dashboard";
import { Home } from "../pages/Home";
import BlogDetail from "../pages/BlogDetail";
import Blog from "../pages/Blog";
import PostEditor from "../pages/PostEditor";
import  { PATHS } from '../const/routeNames';  

export const AppRouter = () => {

  return (
    <Router>
      <Routes>
        <Route path={PATHS.home} element={<Home />} />
        <Route path={PATHS.blog} element={<Blog />} />
        <Route path={PATHS.blogDetail} element={<BlogDetail />} />
        <Route
          path={PATHS.login}
          element={
            <PublicRoute>
              <MainTemplate>
                <Login />
              </MainTemplate>
            </PublicRoute>
          }
        />
        <Route
          path={PATHS.dashboard}
          element={
            <PrivateRoute>
              <MainTemplate>
                <Dashboard />
              </MainTemplate>
            </PrivateRoute>
          }
        />
        <Route
          path={PATHS.editor}
          element={
            <PrivateRoute>
                <PostEditor/>
            </PrivateRoute>
          }
        />
        <Route path="*" element={<Navigate to={PATHS.home} replace />}/>
      </Routes>
    </Router>
  );
};
