import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MainCardPost from "./MainCardPost";
import {
  collection,
  orderBy,
  limit,
  query,
  getDocs,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import {PATHS} from '../const/routeNames';

export default function BlogSection() {
  const [articles, setArticles] = useState([]);

  const getArticles = async () => {
    const articleRef = collection(db, "Articles");
    const q = query(articleRef, orderBy("createdAt", "desc"), limit(3));
    const querySnapshot = await getDocs(q);
    const articles = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setArticles(articles);
  };

  useEffect(() => {
    getArticles();
  }, []);

  return (
    <section className="blog-area section-padding-top" id="blog-page">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 text-center">
            <h3 className="page-title mb-5">
              <Link to={PATHS.blog}>Blog</Link>
            </h3>
            <div className="space-60"></div>
          </div>
        </div>
        {articles.length === 0 ? (
          <p></p>
        ) : (
          <div className="row">
            {articles.length >= 1 && <MainCardPost article={articles[0]} />}
            {articles.length >= 2 && <MainCardPost article={articles[1]} />}
            {articles.length >= 3 && <MainCardPost article={articles[2]} />}
          </div>
        )}
      </div>
    </section>
  );
}
