import React from "react";
// import Modal from "./Modal";

export default function VideoSection() {
  // const [isOpen, openModal, closeModal] = useModal(false);

  return (
    <div>
      {<div id="video-area" className="section-padding-top">
        <div className="container ">
          <div className="row justify-content-center ">

            <iframe className="if_video"
            width="800" height="400" src="https://www.youtube.com/embed/rlo_5FMz_I4?autoplay=1&mute=1" 
            title="YouTube video player" frameborder="0" 
            allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            ></iframe>

            {/* <iframe className="if_video"
              width="800"
              height="400"
              src="https://www.youtube.com/embed/_F2EviyRWvg?autoplay=1&mute=1"
              title="YouTube video player"
              // frameBorder="0"
              allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe> */}
            <div className="col-xs-12 col-md-8 col-md-offset-2 text-center">
              <div className="video-box wow fadeIn" data-wow-delay="0.3s">
                {/* <div className="play-bttn popup cursor_hover" onClick={openModal}>
                  <i className="zmdi zmdi-play"></i>
                </div> */}
                {/* <figure className="video-photo">
                  <img src="images/video-img.jpg" alt="video" />
                </figure> */}




              </div>
            </div>
          </div>
        </div>
      </div>}

      {/* <Modal isOpen={isOpen} closeModal={closeModal}>
        {isOpen && (
          <iframe
            width="800"
            height="500"
            src="https://www.youtube.com/embed/8BAhwgjMvnM"
            title="YouTube video player"
            frameBorder="0"
            allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
      </Modal> */}
    </div>
  );

  //   return (
  //     <div id="video-area" className="section-padding-top">
  //         <div className="container">
  //             <div className="row justify-content-center">
  //                 <div className="col-xs-12 col-md-8 col-md-offset-2 text-center">
  //                     <div className="video-box wow fadeIn" data-wow-delay="0.3s">
  //                         <a href="https://www.youtube.com/watch?v=8BAhwgjMvnM" className="play-bttn popup">
  //                             <i className="zmdi zmdi-play"></i>
  //                         </a>
  //                         <figure className="video-photo">
  //                             <img src="images/video-img.jpg" alt="video"/>
  //                         </figure>
  //                     </div>
  //                 </div>
  //             </div>
  //         </div>
  //     </div>
  //   )
}
