import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import {LinkContainer} from 'react-router-bootstrap'

export default function NavbarLanding() {

  return (
    
    
    // <Animated animationIn="fadeInUp" isVisible={true} animationInDuration={1000}>
    <Navbar bg="white" expand="lg" fixed="top" style={{textAlign:"center"}}>
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>
            {/* <img src="../images/xsc-logo.png" width="110" alt="logo xscope"/> */}
            <img src="../images/new-logo.jpeg" width="150" alt="logo xscope"/>
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" defaultActiveKey="/#home-page">
            <Nav.Link href="/#home-page">Home</Nav.Link>
            <Nav.Link href="/#about-page">About</Nav.Link>
            <Nav.Link href="/#feature-page">Features</Nav.Link>
            <Nav.Link href="/#gallery-page">Gallery</Nav.Link>
            <Nav.Link href="/#price-page">Pricing</Nav.Link>
            <Nav.Link href="/#blog-page">Blog</Nav.Link>
            <Nav.Link href="/#contact-page">Contact</Nav.Link>
          </Nav>
          <div className="navbar-header navbar-right">
                    <a href="https://xscope-measure.net/login" className="navbar-bttn">Login</a>
                </div>
          <div className="navbar-header navbar-right">
                    <a href="https://xscope-measure.net/Signup" className="btn_signup">Sign up</a>
                </div>
        </Navbar.Collapse>
        
      </Container>
    </Navbar>
    // </Animated>
  );
}
