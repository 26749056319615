import React, { useEffect, useState } from "react";
import FooterSection from "../components/FooterSection";
import NavbarLanding from "../components/NavbarLanding";
import {
  collection,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import CardPost from "../components/CardPost";

export default function Blog() {
  const [articles, setArticles] = useState(null);


  const getArticles = async () => {
    const articleRef = collection(db, "Articles");
    const q = query(articleRef, orderBy("createdAt", "desc"));
    const querySnapshot = await getDocs(q);
    const articles = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setArticles(articles);
  };

  useEffect(() => {
    getArticles();
  }, []);


  return (
    <>
    <NavbarLanding />
      {!articles ? (
        <p>no data</p>
      ) : (
        <div>
          <header className="site-header">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 text-center">
                  <h3 className="headline mt-10">Xscope Blog</h3>
                </div>
              </div>
            </div>
          </header>
          <section className="section-padding">
            <div className="container">
              {articles.length === 0 ? (
                <h3 className="text-center">No articles found!</h3>
              ) : (
                <div className="card_post_container">
                  {articles.map((article) => (
                    <CardPost article={article} key={article.id} />
                  ))}
                </div>
              )}
            </div>
          </section>
          <FooterSection />
        </div>
      )}
    </>
  );
}
