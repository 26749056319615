import React from 'react'
import Articles from '../components/Articles'

export const Dashboard = () => {
  return (
    <div className="Container">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <Articles />
        </div>
      </div>
    </div>
  )
}
