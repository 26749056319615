import React from "react";
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../auth/AuthContext";
import {PATHS} from '../const/routeNames';

const PrivateRoute = ({ children }) => {

    const { user } = useContext(AuthContext);

    if (!user.logged) {
        // user is not authenticated
        return <Navigate to={PATHS.login} />;
      } 

  return children
};

export default PrivateRoute;
