import React, { useContext } from "react";
import { AuthContext } from "../auth/AuthContext";
import { useNavigate, Link } from "react-router-dom";
import { types } from "../types/types";
import { Button } from "react-bootstrap";
import {PATHS} from '../const/routeNames'

export default function Navbar() {
  let navigate = useNavigate();

  const {
    dispatch,
  } = useContext(AuthContext);

  const handleLogout = () => {
    navigate(PATHS.login);

    dispatch({
      type: types.logout,
    });
  };

  return (
    <div className="fixed-top border" style={{ backgroundColor: "whitesmoke" }}>
      <nav className="navbar">
        <Link to={PATHS.dashboard}>
          <div>
            <img
              src="/images/xsc-logo.png"
              width={70}
              height={70}
              alt="logo"
              className="ms-5"
            />
          </div>
        </Link>
        <div className="row col-md-3 justify-content-center">
          <div className="col-6">
            <Button variant="primary" onClick={() => navigate("/editor")}>
              Add new
            </Button>
          </div>
          <div className="col-6 pr-3">
            <Button variant="info" onClick={handleLogout}>
              Logout
            </Button>
          </div>
        </div>
      </nav>
    </div>
  );
}
