import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FooterSection from "../components/FooterSection";
import NavbarLanding from "../components/NavbarLanding";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";

export default function BlogDetail() {
  const { postId } = useParams();
  console.log(postId);

  const [article, setArticle] = useState({});

  useEffect(() => {
    (async () => {
      const docRef = doc(db, "Articles", postId);
      try {
        const docSnap = await getDoc(docRef);
        const article = docSnap.data();
        setArticle(article);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [postId]);

  return (
    <div>
      <NavbarLanding />
      <p>.</p>
      {!article.title ? (
        <p></p>
      ) : (
        <section className="section-padding">
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <article className="blog-details">
                  <figure className="blog-photo">
                    <img src={article.imageUrl} alt="" className="image_post_detail"/>
                  </figure>
                  <div className="blog-content blog-content-detail">
                    <div className="post-header">
                      <h2>{article.title}</h2>
                      <ul className="info">
                        {/* <li><i className="zmdi zmdi-account-o"></i> Ashekur Rahman</li> */}
                        <li>
                          <i className="zmdi zmdi-time"></i>{" "}
                          {article.createdAt.toDate().toDateString()}
                        </li>
                      </ul>
                    </div>
                    <div className="blog-summary" dangerouslySetInnerHTML={{ __html: article.description }}/>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </section>
      )}
      <FooterSection />
    </div>
  );
}
