import React from "react";
// import { PopupWidget } from "react-calendly";
import image from "../img/xsc-header.png";
import { Animated } from "react-animated-css";

export default function HeaderSection() {
  return (
    <header
      className="header-area"
      id="home-page"
      style={{
        backgroundImage: `url(${image})`,
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "right",
        backgroundPositionY: "bottom",
        backgroundSize: "100%",
      }}
    >
      <div className="vcenter">
        <div className="container">
          <div className="row">
            <div
              className="col-xs-12 col-sm-9 col-md-7"
              itemScope
              itemType="https://schema.org/Organization"
            >
              <Animated
                animationIn="fadeInUp"
                isVisible={true}
                animationInDuration={1000}
              >
                <h1
                  className="headline wow fadeInUp otro"
                  itemProp="description"
                >
                  Fast, accurate roof <br /> measurement software.
                </h1>
              </Animated>
              <Animated
                animationIn="fadeInUp"
                isVisible={true}
                animationInDuration={1000}
                animationInDelay={600}
              >
                <p className="wow fadeInUp" data-wow-delay="0.6s">
                  The complete tool for Roofers! <br /> Get a fast, accurate
                  estimate in minutes from your truck, <br /> at the clients
                  location!{" "}
                </p>
              </Animated>
              <div className="space-30 hidden-xs"></div>
              <div className="space-30"></div>
              {/* <PopupWidget
                  url="https://calendly.com/xscope-measure/30min"
                  rootElement={document.getElementById("root")}
                  text="Click here to schedule!"
                  textColor="#ffffff"
                  color="#00a2ff"
                /> */}

              <div>
                <a
                  href="https://calendly.com/xscope-measure/30min"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="bttn-icon wow fadeIn book">
                    <div className="bttn-content">
                      <div className="btn_content_inside">
                        <img src="images/xsc-logo-only.png" alt="xscope" />
                        <div>BOOK DEMO</div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              {/* <a href="" onclick="Calendly.initPopupWidget({url: 'https://calendly.com/xscope-measure/30min'});return false;" class="bttn-icon wow fadeIn" data-wow-delay="0.8s">
                            <div className="bttn-content">
                                <div className="btn_content_inside">
                                    <img 
                                        src="images/xsc-logo-only.png" alt="xscope"/>
                                    <div>Book Demo</div>
                                </div>
                            </div>
                        </a> */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
