import Swal from "sweetalert2";

export const Toast = Swal.mixin({
  toast: true,
  position: "center",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const openNewLoading = (title) => {
  Swal.fire({
    title: title,
    background: "#FFFFFF",
    color: "#3C5D6E",
    confirmButtonColor: "#FF844B",
    allowOutsideClick: false,
  });

  Swal.showLoading();
};

export const closeSwal = () => {
    Swal.close();
}
