import React from "react";
import { AnimationOnScroll } from 'react-animation-on-scroll';

export default function NetworkSection() {
  return (
    <section className="network-area section-padding-top">
        <div className="container">
            <div className="row">
                <div className="col-xs-12 col-md-6">
                    <div className="social-logo wow fadeInUp" data-wow-delay="0.3s">
                        <AnimationOnScroll animateIn="animate__fadeInUp" delay={300} animateOnce={true}>
                            <img src="images/about-logo.png" alt="logo about"/>
                        </AnimationOnScroll>
                    </div>
                    <AnimationOnScroll animateIn="animate__fadeInUp" delay={500} animateOnce={true}>
                        <h3 className="page-title wow fadeInUp" data-wow-delay="0.5s">
                            Use photos and videos to help you estimate accurately                            
                        </h3>
                    </AnimationOnScroll>
                    <AnimationOnScroll animateIn="animate__fadeInUp" delay={700} animateOnce={true}>
                        <p className=" wow fadeInUp" data-wow-delay="0.7s">You can now use the platform for taking photos,
                            attach your own pictures and videos for detailed reports. Store unlimited photos and
                            videos. Sync and share your galleries with your team and clients.</p>
                        <div className="space-30"></div>
                    </AnimationOnScroll>
                   
                </div>
                <div className="col-sm-6 hidden-small">
                    <img src="images/pic1_back.png" alt="xscope" className="w-100"/>
                </div>
            </div>
        </div>
    </section>
  );
}
