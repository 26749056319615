import React from 'react'
import rs1 from "../img/rs1.png";
import rs2 from "../img/rs2.png";
import logo from "../img/xsc-logo.png";

export default function FooterSection() {
    return (
        <footer className="footer-area pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-md-3">
                        <img src={logo} width="100" alt="logo xscope" />
                        <div className="space-20"></div>
                        <p>
                            Measure. Estimate. Manage <br />
                            The best solution for your contractor needs.
                        </p>
                        <div className="space-20"></div>
                        <ul className="social-menu">
                            <li><a href="https://www.facebook.com/Xscope-Measuring-107409431860241"><i
                                className="zmdi zmdi-facebook"></i></a></li>
                            <li><a href="https://twitter.com/xscopesoftware"><i className="zmdi zmdi-twitter"></i></a></li>
                            <li><a href="https://www.youtube.com/channel/UCSBGCGeMDaXPJd2xrfnv9vw"><i
                                className="zmdi zmdi-youtube-play"></i></a></li>

                            <li><a href="https://www.instagram.com/x_scope.measuring"><img className="borderLink"
                                width="40" height="40" src={rs1} alt="instagram icon" /></a></li>
                            <li><a href="https://www.linkedin.com/company/xscope-inc/"><img
                                className="borderLink" width="40" height="40" src={rs2} alt="linkedin icon" /></a></li>
                        </ul>
                        <div className="space-20"></div>
                        <p id="pageyear">{`Copyright Xscope Measuring Inc. ${new Date().getFullYear()}`}</p>
                        <div className="space-50"></div>
                    </div>
                    <div className="col-xs-12 col-md-2">
                        <h4>About</h4>
                        <div className="space-10"></div>
                        <ul className="list">
                            <li><a href="/">About Us</a></li>
                            <li><a href="/">Partners</a></li>
                            <li><a href="/">Career</a></li>
                            <li><a href="/">Reviews</a></li>
                            <li><a href="/">Terms &amp; Conditions</a></li>
                            <li><a href="/">Help</a></li>
                        </ul>
                        <div className="space-50"></div>
                    </div>
                    <div className="col-xs-12 col-md-3">
                        <h4>Contact</h4>
                        <div className="space-10"></div>
                        <ul className="icon-list">
                            <li>
                                <div className="icon">
                                    <i className="zmdi zmdi-pin zmdi-hc-fw"></i>
                                </div>
                                <strong>Location</strong> : 6711 NW 44th Street Coral Springs FL 33067
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="zmdi zmdi-email"></i>
                                </div>
                                <strong>Mail</strong> : info@x-scope.net
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="zmdi zmdi-phone-in-talk"></i>
                                </div>
                                <strong>Phone</strong> : 954-234-8912
                            </li>
                        </ul>
                        <div className="space-50"></div>
                    </div>
                    <div className="col-xs-12 col-md-4">
                        <h4>Newsletter</h4>
                        <div className="space-10"></div>
                        <p>We don't send spam or unwanted messages.</p>
                        <div className="space-20"></div>
                        <form id="mc-form" className="subscribe">
                            <input type="text" className="control" placeholder="Email" id="mc-email" />
                            <button className="sub-button" type="submit"><i className="zmdi zmdi-long-arrow-right"></i></button>
                            <label className="mt10" htmlFor="mc-email"></label>
                        </form>
                        <div className="space-50"></div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
