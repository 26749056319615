import React, { useState } from "react";
import {
  addDoc,
  collection,
  Timestamp,
  setDoc,
  doc,
} from "firebase/firestore";
import {
  ref,
  deleteObject,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { db, storage } from "../firebaseConfig";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import { PATHS } from '../const/routeNames';



const moduleConfig = {
  toolbar: [
    [{ 'header': [] }, { 'font': [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { 'list': 'ordered' },
      { 'list': 'bullet' },
      { 'indent': '-1' },
      { 'indent': '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
    [{ 'color': [] }, { 'background': [] }],
    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    // [],
  ],
  
}



export default function AddArticle({ props }) {
  const navigate = useNavigate();
  const article = props?.article;

  const [formData, setFormData] = useState({
    title: article ? article.title : "",
    description: article ? article.description : "",
    image: article ? article.imageUrl : "",
    createdAt: Timestamp.now().toDate(),
  });

  const [progress, setProgress] = useState(0);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleChangeDescription = (value) => {
    setFormData({ ...formData, description: value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };
  const handlePublish = (e) => {
    if (!formData.title || !formData.description || !formData.image) {
      alert("Please fill all the fields");
      return;
    }
    const storeRef = ref(
      storage,
      `/images/${Date.now()}${formData.image.name}`
    );
    const uploadImage = uploadBytesResumable(storeRef, formData.image);

    uploadImage.on(
      "state_changed",
      (snapshot) => {
        const progressPercent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progressPercent);
      },
      (err) => {
        console.log(err);
      },
      () => {
        setFormData({
          title: "",
          description: "",
          image: "",
        });

        getDownloadURL(uploadImage.snapshot.ref).then((url) => {
          const articleRef = collection(db, "Articles");
          addDoc(articleRef, {
            title: formData.title,
            description: formData.description,
            imageUrl: url,
            createdAt: Timestamp.now().toDate(),
          })
            .then(() => {
              toast("Article added successfully", { type: "success" });
              setProgress(0);

              navigate("/dashboard");
            })
            .catch((err) => {
              toast("Error adding article", { type: "error" });
            });
        });
      }
    );
  };

  const handleEditPublish = async (e) => {
    // if (!formData.title || !formData.description || !formData.image) {
    //   alert("Please fill all the fields");
    //   return;
    // }

    const updateData = {
      title: formData.title,
      description: formData.description,
      // imageUrl: formData.image
    };

    const articleRef = doc(db, "Articles", article.id);

    // image to updated
    if (formData.image !== article.imageUrl) {
      // delete old image
      try {
        const storageRef = ref(storage, article.imageUrl);
        await deleteObject(storageRef);
      } catch (error) {
        toast("Error deleting article", { type: "error" });
        console.log(error);
      }

      // add new image
      const storeRef = ref(
        storage,
        `/images/${Date.now()}${formData.image.name}`
      );
      const uploadImage = uploadBytesResumable(storeRef, formData.image);

      uploadImage.on(
        "state_changed",
        (snapshot) => {
          const progressPercent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progressPercent);
        },
        (err) => {
          console.log(err);
        },
        () => {
          setFormData({
            title: "",
            description: "",
            image: "",
          });

          getDownloadURL(uploadImage.snapshot.ref).then((url) => {
            // add url to updateData
            updateData.imageUrl = url;
            setDoc(articleRef, updateData, { merge: true })
              .then(() => {
                toast("Article edited successfully", { type: "success" });
                setProgress(0);
                navigate(PATHS.dashboard);
              })
              .catch((err) => {
                toast("Error edited article", { type: "error" });
              });
          });
        }
      );
    } else {
      // image not changed
      await setDoc(articleRef, updateData, { merge: true });
      toast("Article edited successfully", { type: "success" });
      navigate(PATHS.dashboard);
    }
  };

  return (
    <div className="border p-3 mt-3 bg-light">
      <h2>{props ? "Edit Article" : "Create Article"}</h2>
      <label htmlFor="">Title</label>
      <input
        type="text"
        name="title"
        className="form-control"
        value={formData.title}
        onChange={(e) => handleChange(e)}
      />

      {/* description */}
      <br />
      <label htmlFor="">Content</label>
      <ReactQuill
        theme="snow"
        value={formData.description}
        onChange={(e) => handleChangeDescription(e)}
        modules = {moduleConfig}
      />

      {/* image */}
      {article && (
        <div className="editable_image_container">
          <img src={article.imageUrl} alt={article.title} />
        </div>
      )}

      <label htmlFor="">Select Image</label>
      <input
        type="file"
        name="image"
        accept="image/*"
        className="form-control"
        onChange={(e) => handleImageChange(e)}
      />

      {/* progress bar */}
      {progress === 0 ? null : (
        <div className="progress">
          <div
            className="progress-bar progress-bar-striped mt-2"
            style={{ width: `${progress}%` }}
          >
            {`Uploaded image ${progress}%`}
          </div>
        </div>
      )}

      <button
        className="form-control btn btn-primary mt-2"
        onClick={article ? handleEditPublish : handlePublish}
      >
        {article? 'Edit and Publish' : 'Publish'}
      </button>
    </div>
  );
}
