import React from "react";
import { useNavigate } from "react-router-dom";


export default function CardPost({article}) {
  const navigate = useNavigate()

  return (
    <div className="col-xs-12 col-sm-6 card_blog" role="button" onClick={()=> navigate(`/blog/${article.id}`) }>
      <article className="single-blog">
        <figure className="blog-photo">
          <img className="card_image" src={article.imageUrl} alt={article.title} />
        </figure>
        <div className="blog-content description_container">
          <header>
            <h5 className="title cursor_pointer">{article.title}</h5>
            <ul className="blog-meta">
              {/* <li>
                <i className="zmdi zmdi-account-o"></i>Ashekur Rahman
              </li> */}
              <li>
                <i className="zmdi zmdi-time"></i> {article.createdAt.toDate().toDateString()}
              </li>
            </ul>
          </header>
          <div className="space-20"></div>
          <p className="card_description" dangerouslySetInnerHTML={{ __html: article.description }}/>
          <div className="space-20"></div>
          <footer className="blog-footer cursor_pointer">
            Read More
          </footer>
        </div>
      </article>
    </div>
  );
}
